import { message } from "antd";
import { get, post, put } from "../../../utils/request";
import { globalPromptTime, globalPromptTime1s } from "../../../utils/global";

const SAVE = "pamb/userManagementReducer/SAVE";

const initState = {
  userData: {
    total: 0,
    pageSize: 10,
    pageNumber: 1,
    userList: [],
    queryUserListLoading: false
  },
  userStatusList: [],
  rolerList: [],
  _rolerList: [],
  buList: [],
  rankList: [],
  allRolerList: [],
  allRoleList: [],
  resendEmaiLoading: false,
  activateLoading: false,
  noRefrash: false,
  usefulTemporaryData: { currentNum: 1, fieldsData: {} }
};

// reducer
export default function userManagementReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function getUserList(payload, buRole) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        queryUserListLoading: true
      }
    });
    post("/user/userList", payload, { buRole })
      .then(res => {
        const {
          data: { data, total, currentPageNumber, pageSize }
        } = res;
        const userData = {
          total,
          pageSize,
          pageNumber: currentPageNumber,
          userList: data
        };
        dispatch({
          type: SAVE,
          payload: {
            userData,
            queryUserListLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            queryUserListLoading: false
          }
        });
      });
  };
}

export function getTrainerInfo(type) {
  return dispatch => {
    get('/trainer/roleInfo', { type })
      .then(res => {
        const { data } = res;
        if (type === 'TRAINER_ROLE_TYPE') {
          dispatch({
            type: SAVE,
            payload: {
              trainerRoleTypeList: data
            }
          })
        } else if (type === 'TRAINER_ROLE_ACCESS') {
          dispatch({
            type: SAVE,
            payload: {
              trainerRoleAccessList: data
            }
          })
        }
      })
  }
}

export function updateRoleInfo(payload, userId, cb = () => { }) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        updateRoleLoading: true
      }
    });

    put(`/trainer/${userId}/roleInfo`, payload)
      .then(res => {
        cb();
        dispatch({
          type: SAVE,
          payload: {
            updateRoleLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            updateRoleLoading: false
          }
        })
      })
  }
}

export function getRolerList() {
  return dispatch => {
    get("/user/roleList")
      .then(res => {
        const { data } = res;
        // PCAAEB-52786
        const rolerList = data.filter(item => ["PAMBAgent", "PBTBAgent"].includes(item.code));
        dispatch({
          type: SAVE,
          payload: {
            rolerList
          }
        });
      })
      .catch(() => {

      });
  };
}

export function get_RolerList() {
  return dispatch => {
    get("/user/getCourseUserSelectForUserManagement")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            _rolerList: data
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getRankList() {
  return dispatch => {
    get("/user/rankList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            rankList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getUserStatusList() {
  return dispatch => {
    get("/user/getStatusList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            userStatusList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getAllRoleList() {
  return dispatch => {
    get("/user/allRoleList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            allRoleList: data
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getAllRolerList() {
  return dispatch => {
    get("/systemSetting/getRoleList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            allRolerList: data
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getBuList() {
  return dispatch => {
    get("/systemSetting/getBuList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            buList: data
          }
        });
      })
      .catch(() => {

      });
  };
}

export function resendEmail(payload, buRole) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        resendEmaiLoading: true
      }
    });

    get("/login/generateLink", payload, { buRole })
      .then(() => {
        message.info("Operation Success", globalPromptTime);
        dispatch({
          type: SAVE,
          payload: {
            resendEmaiLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            resendEmaiLoading: false
          }
        });
      });
  };
}

export function handleActivate(payload, buRole, type, callback) {
  const url = `/user/${type}`;
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        activateLoading: true
      }
    });

    post(url, payload, { buRole })
      .then(() => {
        message.info("Operation Success！", globalPromptTime1s, callback);
        dispatch({
          type: SAVE,
          payload: {
            activateLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            activateLoading: false
          }
        });
      });
  };
}

export function saveFields(field, val) {
  return (dispatch, getState) => {
    const {
      pambReducer: { systemSettingsReducers: {
        userManagementReducer: { usefulTemporaryData }
      } }
    } = getState();
    const { fieldsData } = usefulTemporaryData;
    fieldsData[field] = val;
    dispatch({
      type: SAVE,
      payload: {
        usefulTemporaryData
      }
    });
  };
}

export function saveTemporaryData(payload) {
  return (dispatch, getState) => {
    const {
      pambReducer: { systemSettingsReducers: {
        userManagementReducer: { usefulTemporaryData }
      } }
    } = getState();
    dispatch({
      type: SAVE,
      payload: {
        usefulTemporaryData: { ...usefulTemporaryData, ...payload }
      }
    });
  };
}

export function handleRefrash(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        noRefrash: payload
      }
    });
  };
}
